<script setup lang="ts">
import type { Game } from "@/types";

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	type?: "slider_vertical" | "slider_horizontal";
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);
const { isMobileClient } = useWindowClient();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const defaultBg = "/nuxt-img/card-games/default-bg.png";
const isGameFavorite = computed(() => props.game.isFavorite);

const handleToggleToFavorite = () => {
	handleToggleToFavoriteClick({ ...props.game, isFavorite: isGameFavorite.value });
};

const handleOpenGameClick = () => {
	loginGuard({
		success() {
			handleOpenGame(props.game.slug);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div>
		<MGameCardHorizontal
			v-if="type === 'slider_horizontal'"
			:game="game"
			:gameImgHorizontal="`${baseImageUrl}${props.game?.logo2x1 || props.game?.logo}`"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:defaultBg="defaultBg"
			:gameImgIncrease="2"
			:width="284"
			:height="174"
			:buttonName="t('Play')"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCardHorizontal>
		<MGameCard
			v-else
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
			:defaultBg="defaultBg"
			:width="isMobileClient ? 121 : 163"
			:height="isMobileClient ? 214 : 286"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	background: var(--gray-800);
	border-radius: 10px;
}

:deep(.game-card) {
	&:hover .btn-favorite {
		opacity: 1;
	}
	.image-block {
		border-radius: 10px;
		background: var(--neutral-900);
	}
	.game-overlay {
		background-color: var(--a-overlay-200);
		.nuxt-icon {
			font-size: 64px;
		}
	}
	.btn-favorite {
		opacity: 0;
		top: 8px;
		right: 8px;
		padding: 2px;
		border-radius: 8px;
		background: var(--gray-900);
		width: 28px;
		height: 28px;
	}
}
</style>
